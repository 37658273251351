import * as React from "react";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import DestructiveButton from "../../../Components/UI/cancel-button";

export interface Props {
    specialty: string;
    onSuccess: () => void;
    onClose: () => void;
}

const ClassInstructorRemoveEdit = (props: Props): JSX.Element => {
    const { tp } = useParsedTranslation();
    const { t } = useTranslation();

    return (
        <>
            <div className="class-notes-content">
                { /* // 12321 Need to make this a crowdin string */}
                <p className="mb-5">{tp('EditClass.RemoveInstructor.IsCertified', {specialty: t(props.specialty) })}</p>
                <div className="mt-4 d-flex justify-content-between">
                    <DestructiveButton clickAction={() => props.onClose()} label={<><i className="me-1 far fa-times"/> {t('Close')}</>} />                    
                </div>
            </div>
        </>
    );

};

export default ClassInstructorRemoveEdit;
