import * as React from "react";
import { useTranslation } from "react-i18next";
import useParsedTranslation from "../../../hooks/useParsedTranslation";
import Button from "../../../Components/UI/button";
import DestructiveButton from "../../../Components/UI/cancel-button";

export interface Props {
    specialty: string;
    onSuccess: () => void;
    onClose: () => void;
}

const ClassInstructorRemoveCreate = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { tp } = useParsedTranslation();

    return (
        <>
            <div className="class-notes-content">
                <p className="mb-5">{tp('CreateClass.RemoveInstructor.IsCertified', {specialty: t(props.specialty) })}</p>
                <p className="fw600">{t('Do you want to continue?')}</p>
                <div className="mt-4 d-flex justify-content-between">
                    <DestructiveButton clickAction={() => props.onClose()} label={<><i className="me-1 far fa-times"/> {t('Cancel')}</>} />
                    <Button isSolid label={
                        <>
                            <span>{t('Yes, Remove Instructor')}</span>
                            <i className="fa-sharp fa-light fa-chevron-right"/>
                        </>
                    } clickAction={props.onSuccess} />
                </div>
            </div>
        </>
    );

};

export default ClassInstructorRemoveCreate;
